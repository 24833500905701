import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Autoplay, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import { Swiper, SwiperSlide } from "swiper/react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import arrowBig from "../images/arrow-big.svg"
import "../styles/index.css"

const IndexPage = props => {
  const { t } = useTranslation()
  const { language } = useI18next()

  const aboutContent = props.data?.about?.nodes?.[0]?.frontmatter
  const slides = props.data?.slides?.edges?.[0]?.node?.frontmatter?.slide
  return (
    <Layout>
      <Seo title={t("Anasayfa")} />
      <Swiper
        loop="true"
        modules={[EffectFade, Autoplay]}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        className="md:mt-16 index"
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {slides?.map((slide, index) => (
          <SwiperSlide
            key={slide.image}
            style={{
              background: `url(${
                language === "en" ? slide.image_en : slide.image
              })`,
              backgroundSize: "cover",
              backgroundPosition: "left",
            }}
          ></SwiperSlide>
        ))}
      </Swiper>
      <div
        id="hakkimizda"
        className="font-second text-default-blue opacity-40 font-270 w-full flex justify-center items-center font-extralight py-24 leading-none tracking-widest mb-6 my-12 md:text-6xl md:mt-4 md:mb-4 md:py-3"
      >
        {t("BizKimiz")}
      </div>
      <div className="w-8/12 mx-auto py-3 mb-10 relative md:w-11/12">
        <StaticImage
          src="../images/dotted-stroke.svg"
          className="absolute top-0 -left-10 md:left-0"
        />
        <p className="font-first text-default text-2xl w-11/12 mx-auto md:w-full md:text-base md:text-center">
          {language === "en"
            ? aboutContent?.content_home_en
            : aboutContent?.content_home}
        </p>
        <Link
          to="/biz-kimiz"
          className="block ml-auto mr-0 w-fit mt-10 text-right text-default-blue font-first text-base pr-1 md:mt-5"
        >
          {t("devami")}
        </Link>
        <StaticImage
          src="../images/dotted-stroke.svg"
          className="absolute bottom-0 -right-16 rotate-180 md:right-0"
        />
      </div>
      <div className="w-full relative">
        <StaticImage src="../images/mekan-tasarimi.png" className="w-full" />
        <h2 className="font-first font-90 text-default-dark absolute bottom-7 left-10 font-light md:text-lg md:bottom-1 md:mb-0 md:left-1 md:leading-none md:font-normal">
          {t("mekanaozel")}
          <br />
          {t("tasarimlar")}
        </h2>
      </div>
      <div className="flex flex-col items-center mb-20 mt-10 md:mb-7">
        <div
          id="urunler"
          className="mb-16 relative pt-4 pb-3 block w-fit mx-auto md:mb-6"
        >
          <StaticImage
            src="../images/dotted-stroke.svg"
            className="absolute top-0 -left-12"
          />
          <Link
            to="/urunler"
            className="font-first uppercase text-default-blue text-lg font-light mb-0 leading-none md:text-base"
          >
            {t("Urunler")}
          </Link>
          <StaticImage
            src="../images/dotted-stroke.svg"
            className="absolute bottom-0 -right-12 rotate-180"
          />
        </div>
        <div className="w-10/12 mx-auto flex items-start justify-between flex-wrap md:w-11/12 md:justify-center">
          <Link
            to="/urunler"
            className="flex flex-col items-center w-1/5 md:w-6/12 md:mb-6"
          >
            <StaticImage
              src="../images/avize.jpg"
              imgClassName="w-full rounded-lg"
              className="md:w-11/12"
            />
            <div className="flex justify-start w-5/6 items-center mt-2 font-first text-lg font-medium text-black/70 md:text-base md:w-11/12">
              {t("Avize")}
              <StaticImage src="../images/arrow.svg" className="ml-3" />
            </div>
          </Link>
          <Link
            to="/urunler"
            className="flex flex-col items-center w-1/5 md:w-6/12 md:mb-6"
          >
            <StaticImage
              src="../images/aplik.jpg"
              imgClassName="w-full rounded-lg"
              className="md:w-11/12"
            />
            <div className="flex justify-start w-5/6 items-center mt-2 font-first text-lg font-medium text-black/70 md:text-base md:w-11/12">
              {t("Aplik")}
              <StaticImage src="../images/arrow.svg" className="ml-3" />
            </div>
          </Link>
          <Link
            to="/urunler"
            className="flex flex-col items-center w-1/5 md:w-6/12 md:mb-6"
          >
            <StaticImage
              src="../images/abajur.jpg"
              imgClassName="w-full rounded-lg"
              className="md:w-11/12"
            />
            <div className="flex  justify-start w-5/6 items-center mt-2 font-first text-lg font-medium text-black/70 md:text-base md:w-11/12">
              {t("Abajur")}
              <StaticImage src="../images/arrow.svg" className="ml-3" />
            </div>
          </Link>
          <Link
            to="/urunler"
            className="flex flex-col items-center w-1/5 md:w-6/12 md:mb-6"
          >
            <StaticImage
              src="../images/lambader.jpg"
              imgClassName="w-full rounded-lg"
              className="md:w-11/12"
            />
            <div className="flex it justify-start w-5/6 items-center mt-2 font-first text-lg font-medium text-black/70 md:text-base md:w-11/12">
              {t("Lambader")}
              <StaticImage src="../images/arrow.svg" className="ml-3" />
            </div>
          </Link>
          <Link
            to="/urunler"
            className="flex flex-col items-center w-1/5 md:w-6/12 md:mb-6"
          >
            <StaticImage
              src="../images/royal.jpg"
              imgClassName="w-full rounded-lg"
              className="md:w-11/12"
            />
            <div className="flex justify-start w-5/6 items-center mt-2 font-first text-lg font-medium text-black/70 md:text-base md:w-11/12">
              {t("Aksesuar")}
              <StaticImage src="../images/arrow.svg" className="ml-3" />
            </div>
          </Link>
        </div>
      </div>
      <div className="max-w-full overflow-hidden">
        <div className="inline-block py-20 overflow-hidden pb-28 whitespace-nowrap animate-marquee md:py-3">
          <p className="inline-block font-extralight leading-none cursor-default font-second font-250 text-default-blue opacity-20 xl:text-9xl md:text-7xl mx-6">
            {t("hayalinesekil")}
          </p>
          <p className="inline-block font-extralight leading-none cursor-default font-second font-250 text-default-blue opacity-20 xl:text-9xl md:text-7xl">
            {t("hayalinesekil")}
          </p>
        </div>
      </div>
      <div className="w-10/12 mx-auto flex justify-between flex-wrap items-start md:w-11/12">
        <div className="w-1/5 flex flex-col items-center md:w-5/12">
          <StaticImage src="../images/process-icon1.png" />
          <h4 className="font-second text-default font-normal text-3xl mt-6 mb-4 md:mt-4 md:mb-1 md:text-xl">
            {t("modelsecimi")}
          </h4>
          <p className="w-full font-first text-sm text-default-gray text-center md:text-xs">
            {t("modelsecimitext")}
          </p>
        </div>
        <img src={arrowBig} className="mt-8" alt="forward" />
        <div className="w-1/5 flex flex-col items-center md:w-5/12">
          <StaticImage src="../images/process-icon2.png" />
          <h4 className="font-second text-default font-normal text-3xl mt-6 mb-4 md:mt-4 md:mb-1 md:text-xl">
            {t("malzemesecimi")}
          </h4>
          <p className="w-full font-first text-sm text-default-gray text-center md:text-xs">
            {t("malzemesecimitext")}
          </p>
        </div>
        <img src={arrowBig} className="mt-8 md:hidden" alt="forward" />
        <div className="w-1/5 flex flex-col items-center md:w-5/12">
          <StaticImage src="../images/process-icon3.png" />
          <h4 className="font-second text-default font-normal text-3xl mt-6 mb-4 md:mt-4 md:mb-1 md:text-xl">
            {t("projeonayi")}
          </h4>
          <p className="w-full font-first text-sm text-default-gray text-center md:text-xs">
            {t("projeonayitext")}
          </p>
        </div>
        <img src={arrowBig} className="mt-8" alt="forward" />
        <div className="w-1/5 flex flex-col items-center md:w-5/12">
          <StaticImage src="../images/process-icon4.png" />
          <h4 className="font-second text-default font-normal text-3xl mt-6 mb-4 md:mt-4 md:mb-1 md:text-xl">
            {t("uretimasamasi")}
          </h4>
          <p className="w-full font-first text-sm text-default-gray text-center md:text-xs">
            {t("uretimasamasitext")}
          </p>
        </div>
      </div>
      <div className="relative w-full mt-12 mb-8 md:mt-8">
        <StaticImage src="../images/kristal-sade-taslar.png" />
        <h2 className="absolute bottom-20 right-7 text-default-green font-90 text-right font-first font-light md:text-lg md:leading-none md:bottom-4 md:right-2 md:font-normal md:mb-0">
          {t("kristalsadeve")}
          <br />
          {t("renklitaslar")}
        </h2>
      </div>
      <div
        id="iletisim"
        className="relative w-11/12 mx-auto my-12 flex items-stretch justify-between md:flex-col md:items-center md:justify-center"
      >
        <h1 className="text-[12vw] text-default-blue opacity-20 font-light font-second flex items-center md:hidden">
          {t("bize")}
        </h1>
        <h1 className="hidden md:block font-second font-light opacity-20 text-default-blue text-6xl">
          {t("bizeulasinmobil")}
        </h1>
        <a
          href="https://goo.gl/maps/V2PcXwg5pRDoHY8S7"
          target={"_blank"}
          rel="noreferrer"
          className="w-[45vw] md:w-full"
        >
          <StaticImage src="../images/map.png" />
        </a>
        <h1 className="text-[12vw] flex items-center text-default-blue opacity-20 font-light font-second -z-10 md:hidden">
          {t("ulasin")}
        </h1>
        <Link
          to="/iletisim"
          className="font-first text-base font-light text-default-blue flex items-center absolute bottom-0 right-0 leading-none"
        >
          {t("bizeulasinmobil")}
          <StaticImage src="../images/circle-arrow.svg" className="ml-2" />
        </Link>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    about: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "about" } } }
    ) {
      nodes {
        frontmatter {
          content_home
          content_home_en
        }
      }
    }
    slides: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "slide" } } }
    ) {
      edges {
        node {
          frontmatter {
            slide {
              image
              image_en
            }
          }
        }
      }
    }
  }
`
